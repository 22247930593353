import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I veckans avsnitt snackar vi om nya htop, Firefox och tar en titt på Linux då och nu. Vi fortsätter med Apple vs Epic runda 2, och avslutar med trevligheter om programspråk och typsnitt.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`htop-dev 3.0.0: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/iiowpn/htop_forked_to_htopdev_and_version_300_released/"
        }}>{`https://www.reddit.com/r/linux/comments/iiowpn/htop_forked_to_htopdev_and_version_300_released/`}</a></li>
      <li parentName="ul">{`Firefox 80: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/80.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/80.0/releasenotes/`}</a></li>
      <li parentName="ul">{`2020 Linux Kernel History Report: `}<a parentName="li" {...{
          "href": "https://www.linuxfoundation.org/wp-content/uploads/2020/08/2020_kernel_history_report_082620v2.pdf"
        }}>{`https://www.linuxfoundation.org/wp-content/uploads/2020/08/2020_kernel_history_report_082620v2.pdf`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Apple försökte stänga ner Unreal Engine: `}<a parentName="li" {...{
          "href": "https://www.theverge.com/2020/8/26/21402443/epic-fortnite-apple-unreal-engine-ios-game-developers-lawsuit"
        }}>{`https://www.theverge.com/2020/8/26/21402443/epic-fortnite-apple-unreal-engine-ios-game-developers-lawsuit`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Microsoft siktar in sig på Rust: `}<a parentName="li" {...{
          "href": "https://medium.com/the-innovation/how-microsoft-is-adopting-rust-e0f8816566ba"
        }}>{`https://medium.com/the-innovation/how-microsoft-is-adopting-rust-e0f8816566ba`}</a>{` `}<a parentName="li" {...{
          "href": "https://thenewstack.io/microsoft-rust-is-the-industrys-best-chance-at-safe-systems-programming/"
        }}>{`https://thenewstack.io/microsoft-rust-is-the-industrys-best-chance-at-safe-systems-programming/`}</a></li>
      <li parentName="ul">{`The Polite Type: `}<a parentName="li" {...{
          "href": "https://www.thepolitetype.com/"
        }}>{`https://www.thepolitetype.com/`}</a><ul parentName="li">
          <li parentName="ul">{`Andra coola exempel: `}<a parentName="li" {...{
              "href": "https://speckyboy.com/variable-font-examples/"
            }}>{`https://speckyboy.com/variable-font-examples/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Intet nytt på utmaningsfronten `}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      